import { Helmet } from 'react-helmet';
// import { MetaHemletProps } from '../@types';

const MetaHemletTags = (props) => {
  return (
    <Helmet defer={false}>
      {props.pageTitle && <title>{props.pageTitle}</title>}
      {/*PRIMARY META TAGS*/}
      <meta name='title' content={`${props.primaryTitle}`} />
      <meta name='description' content={`${props.primaryDescription}`} />
      {/* Open Graph / Facebook */}
      <meta property='og:type' content={`${props.fbType}`} />
      <meta property='og:url' content={window.location.href} />
      <meta property='og:site_name' content={`Lunor.Quest`} />
      <meta property='og:title' content={`${props.fbTitle}`} />
      <meta property='og:description' content={`${props.fbDescription}`} />
      <meta property='og:image' content={`${props.fbImage}`} />
      {/* Twitter */}
      <meta property='twitter:card' content={`${props.twitterCard}`} />
      <meta property='twitter:url' content={window.location.href} />
      <meta property='twitter:description' content={`${props.twitterDescription}`} />
      <meta property='twitter:image' content={`${props.twitterImage}`} />
      <meta property='twitter:title' content={`${props.twitterTitle}`} />
    </Helmet>
  );
};

export default MetaHemletTags;
