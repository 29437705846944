export const primaryMetaTags = {
  title: 'Lunor.Quest | Web3 Data Challenge Platform',
  description: 'Desights | A Web3 platform to crowdsource solutions to toughtest AI/ML challenges',
};

export const FBmetaOgTags = {
  type: 'website',
  url: 'https://desights.ai/',
  title: 'Lunor.Quest | Web3 Data Challenge Platform',
  description: 'Desights | A Web3 platform to crowdsource solutions to toughtest AI/ML challenges',
  image: 'https://desights.ai/desights-logo-white.png',
};

export const TwitterOgTags = {
  card: 'summary_large_image',
  url: 'https://desights.ai/',
  title: 'Lunor.Quest | Web3 Data Challenge Platform',
  description: 'Desights | A Web3 platform to crowdsource solutions to toughtest AI/ML challenges',
  image: 'https://desights.ai/desights-logo-white.png',
};
